<template>
  <v-container>
    <!-- Dialog add invoice -->
    <v-dialog v-model="dialogCreate" :width="572" persistent>
      <v-card class="pa-8" shaped outlined>
        <v-container fluid class="pa-0">
          <v-row align="center">
            <v-col cols="12" class="d-flex justify-start align-center">
              <span class="text-h6 font-weight-bold">Agregar factura</span>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12">
              <span
                >Ingresa la siguiente información para agregar una nueva
                factura</span
              >
            </v-col>
          </v-row>

          <!-- Invoice -->
          <v-row class="ma-0">
            <v-text-field
              v-model="editableData.sInvoiceNumber"
              type="text"
              outlined
              :label="texts.modalForm.invoice"
              dense
              required
              :placeholder="texts.modalForm.invoice"
              persistent-placeholder
            ></v-text-field>
          </v-row>

          <!-- Provider -->
          <v-row class="ma-0">
            <v-select
              v-model="editableData.sProvider"
              :items="contacts"
              item-text="name"
              item-value="id"
              outlined
              :label="texts.modalForm.provider"
              dense
              required
              :placeholder="texts.modalForm.provider"
              persistent-placeholder
              append-icon="mdi mdi-chevron-down"
            ></v-select>
          </v-row>

          <!-- if is new provider show options -->
          <div v-if="showCreateProvider" class="ml-10 mr-10">
            <v-row>
              <v-text-field
                v-model="contactData.sName"
                type="text"
                outlined
                dense
                :label="texts.registerProvider.name"
                :placeholder="texts.registerProvider.name"
                persistent-placeholder
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="contactData.sEmail"
                type="text"
                outlined
                dense
                :label="texts.registerProvider.email"
                :placeholder="texts.registerProvider.email"
                persistent-placeholder
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="contactData.sBank_name"
                type="text"
                outlined
                dense
                :label="texts.registerProvider.bank_name"
                :placeholder="texts.registerProvider.bank_name"
                persistent-placeholder
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="contactData.sAccount_number"
                type="text"
                outlined
                :label="texts.registerProvider.clabe"
                dense
                :placeholder="texts.registerProvider.clabe"
                persistent-placeholder
              ></v-text-field>
            </v-row>
          </div>

          <!-- Purchase Date -->
          <v-row class="ma-0">
            <v-text-field
              v-model="editableData.dPurchaseDate"
              type="date"
              outlined
              dense
              :label="texts.modalForm.purchaseDate"
              required
              :placeholder="texts.modalForm.purchaseDate"
              persistent-placeholder
            ></v-text-field>
          </v-row>

          <!-- Total amount -->
          <v-row class="ma-0">
            <v-text-field
              v-model="editableData.dTotalAmount"
              type="number"
              outlined
              dense
              :label="texts.modalForm.totalAmount"
              required
              :placeholder="texts.modalForm.totalAmount"
              persistent-placeholder
            ></v-text-field>
          </v-row>

          <!-- Credit period -->
          <v-row class="ma-0">
            <v-text-field
              v-model="editableData.iCreditPeriod"
              type="number"
              outlined
              dense
              :label="texts.modalForm.creditPeriod"
              required
              :placeholder="texts.modalForm.creditPeriod"
              persistent-placeholder
            ></v-text-field>
          </v-row>

          <!-- Protected usd -->
          <v-row class="ma-0">
            <v-text-field
              type="number"
              v-model="editableData.dInvoiceExchangeRateProtection"
              outlined
              dense
              :label="texts.modalForm.exchangeRateProtection"
              required
              :placeholder="texts.modalForm.exchangeRateProtection"
              persistent-placeholder
            ></v-text-field>
          </v-row>

          <v-row justify="end" class="mt-4">
            <v-btn text class="mr-4" @click="closeModalCreate()">
              <div class="cancel-text">Cancelar</div>
            </v-btn>
            <v-btn
              @click="saveChanges"
              :disabled="!validateForm"
              elevation="0"
              :loading="bLoading"
              class="button-save mon-bold"
            >
              <div class="textButton">Agregar factura</div>
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!--Pay modal-->
    <v-dialog persistent v-model="dialogPay" :width="screenWidth * 2.5 + '%'">
      <div class="container-dialog">
        <close-dialog-component @closeDialog="closeModalPay()" />
        <div class="display-flex align-items-center justify-center flex-column">
          <div class="mt-1">
            <h3 class="text-center">
              Invoices a pagar
              <!-- con el tipo de cambio actual {{ actualCurrency }} -->
            </h3>
          </div>

          <!-- Tabla con los invoices seleccionados -->
          <v-simple-table style="width: 100%">
            <template class="full-width">
              <thead>
                <tr>
                  <th class="text-center">
                    {{ texts.dataTable.provider }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.invoice }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.totalAmount }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.exchangeRateProtection }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.currencyProfit }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.totalProtectedUsd }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.totalUsdToday }}
                  </th>
                  <th class="text-center">
                    {{ texts.dataTable.currencyGain }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="exchangeRateData in itemsSelected"
                  :key="exchangeRateData.id"
                >
                  <td class="text-center">
                    {{ exchangeRateData.sProviderName }}
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.sInvoiceNumber }}
                  </td>
                  <td class="text-center">
                    ${{ formatMoney(exchangeRateData.dTotalAmount) }}
                    MXN
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.dInvoiceExchangeRateProtection }}
                    MXN
                  </td>
                  <td class="text-center">
                    {{ exchangeRateData.dCurrencyProfitPercentage }} %
                  </td>
                  <td class="text-center">
                    {{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
                    USD
                  </td>
                  <td class="text-center">
                    {{ formatMoney(exchangeRateData.dTotalUsdToday) }}
                    USD
                  </td>
                  <td class="text-center">
                    {{ formatMoney(exchangeRateData.dCurrencyGain) }}
                    USD
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-if="isResponsive" class="mt-2">
          <v-btn
            elevation="0"
            @click="sendPayEfex()"
            :loading="bLoading"
            :disabled="!validateForm"
            class="button-primary mon-bold"
            style="width: 100%"
          >
            Pay
          </v-btn>
          <v-btn
            elevation="0"
            @click="closeModalPay()"
            class="button-secondary mon-regular mt-5 mb-2"
            style="width: 100%"
          >
            {{ texts.modalForm.cancel }}
          </v-btn>
        </div>
        <div v-else class="display-flex align-items-center mt-2">
          <v-btn
            elevation="0"
            @click="closeModalPay()"
            class="button-cancel mon-regular"
            style="max-width: 200px"
          >
            {{ texts.modalForm.cancel }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            @click="sendPayEfex()"
            :loading="bLoading"
            :disabled="!validateForm"
            class="button-save mon-bold"
            style="max-width: 200px"
          >
            Pay
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <!-- edit modal -->
    <v-dialog
      persistent
      v-model="dialogEditProfileActive"
      :width="screenWidth + '%'"
    >
      <div class="container-dialog">
        <close-dialog-component @closeDialog="closeDialog" />
        <p class="text-title mon-bold mt-2">
          {{ texts.modalForm.textTitle }}
        </p>
        <div class="mt-9">
          <v-col>
            <v-row cols="12" md="6">
              <v-text-field
                v-model="editableData.dPurchaseDate"
                type="date"
                :label="formatDate(editableData.dPurchaseDate)"
                outlined
                color="#000000"
                dense
                :placeholder="texts.modalForm.purchaseDate"
                persistent-placeholder
              ></v-text-field>
              <v-text-field
                v-model="editableData.sProviderName"
                type="text"
                disabled
                :label="texts.modalForm.provider"
                outlined
                color="#000000"
                dense
                :placeholder="texts.modalForm.provider"
                persistent-placeholder
              ></v-text-field>
            </v-row>

            <v-row cols="12" md="6">
              <v-text-field
                v-model="editableData.sInvoiceNumber"
                type="text"
                :label="texts.modalForm.invoice"
                outlined
                color="#000000"
                dense
                :placeholder="texts.modalForm.invoice"
                persistent-placeholder
              ></v-text-field>
              <v-text-field
                v-model="editableData.dTotalAmount"
                type="text"
                :label="texts.modalForm.totalAmount"
                outlined
                :disabled="modalCurrency !== 0"
                color="#000000"
                dense
                :placeholder="texts.modalForm.totalAmount"
                persistent-placeholder
              ></v-text-field>
            </v-row>

            <v-row cols="12" md="6">
              <v-text-field
                v-model="editableData.iCreditPeriod"
                type="text"
                :label="texts.modalForm.creditPeriod"
                outlined
                color="#000000"
                dense
                :placeholder="texts.modalForm.creditPeriod"
                persistent-placeholder
              ></v-text-field>

              <v-text-field
                v-model="editableData.dDueDate"
                type="text"
                :label="formatDate(editableData.dDueDate)"
                outlined
                color="#000000"
                dense
                disabled
                :placeholder="texts.modalForm.dueDate"
                persistent-placeholder
              ></v-text-field>
            </v-row>

            <v-row cols="12" md="6">
              <v-text-field
                type="text"
                v-model="editableData.dInvoiceExchangeRateProtection"
                :label="texts.modalForm.exchangeRateProtection"
                outlined
                color="#000000"
                :disabled="modalCurrency !== 0"
                dense
                :placeholder="texts.modalForm.exchangeRateProtection"
                persistent-placeholder
              ></v-text-field>

              <v-text-field
                type="text"
                v-model="actualCurrency"
                :label="texts.modalForm.exchangeRateToday"
                outlined
                disabled
                color="#000000"
                dense
                :placeholder="texts.modalForm.exchangeRateToday"
                persistent-placeholder
              ></v-text-field>
            </v-row>

            <v-row cols="12" md="6">
              <v-text-field
                v-model="editableData.dTotalProtectedUsd"
                type="text"
                :label="texts.modalForm.totalProtectedUsd"
                outlined
                color="#000000"
                dense
                disabled
                :placeholder="texts.modalForm.totalProtectedUsd"
                persistent-placeholder
              ></v-text-field>
              <v-text-field
                v-model="editableData.dTotalUsdToday"
                type="text"
                :label="texts.modalForm.totalUsdToday"
                outlined
                color="#000000"
                dense
                disabled
                :placeholder="texts.modalForm.totalUsdToday"
                persistent-placeholder
              ></v-text-field>
            </v-row>

            <v-row cols="12" md="6">
              <v-text-field
                type="text"
                v-model="editableData.dCurrencyProfitPercentage"
                :label="texts.modalForm.currencyProfit"
                outlined
                color="#000000"
                dense
                disabled
                :placeholder="texts.modalForm.currencyProfit"
                persistent-placeholder
              ></v-text-field>

              <v-text-field
                type="text"
                v-model="editableData.dCurrencyGain"
                :label="texts.modalForm.currencyProfitUsd"
                outlined
                color="#000000"
                dense
                disabled
                :placeholder="texts.modalForm.currencyProfitUsd"
                persistent-placeholder
              ></v-text-field>
            </v-row>

            <v-row cols="6" md="6" v-if="modalCurrency === 1">
              <v-text-field
                type="text"
                v-model="editableData.dAmountAdjustment"
                :label="texts.modalForm.adjust"
                outlined
                color="#000000"
                dense
                :placeholder="texts.modalForm.adjust"
                persistent-placeholder
              ></v-text-field>
            </v-row>

            <!-- <v-row>
              <v-checkbox
                v-model="editableData.bIsProtected"
                label="Solicitar factoraje"
                color="#000000"
                dense
              ></v-checkbox>
            </v-row> -->
          </v-col>
        </div>
        <div v-if="isResponsive" class="mt-2">
          <v-btn
            elevation="0"
            @click="updateExchangeRate"
            :loading="bLoading"
            :disabled="!validateForm"
            class="button-primary mon-bold"
            style="width: 100%"
          >
            {{ texts.textTitle }}
          </v-btn>
          <v-btn
            elevation="0"
            @click="closeDialog"
            class="button-secondary mon-regular mt-5 mb-2"
            style="width: 100%"
          >
            {{ texts.modalForm.cancel }}
          </v-btn>
        </div>
        <div v-else class="display-flex align-items-center mt-2">
          <v-btn
            elevation="0"
            @click="closeDialog"
            class="button-cancel mon-regular"
          >
            {{ texts.modalForm.cancel }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            @click="updateExchangeRate"
            :loading="bLoading"
            :disabled="!validateForm"
            class="button-save mon-bold"
          >
            {{ texts.textTitle }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <!-- Stepper modal -->
    <v-dialog
      persistent
      v-model="stepper"
      :width="screenWidth * 3 + '%'"
      :height="screenWidth * 120 + '%'"
    >
      <v-stepper v-model="e1" alt-labels elevation="0">
        <close-dialog-component @closeDialog="closeModalStepper()" />
        <v-container class="mt-1">
          <h4>
            Invoices a pagar con el tipo de cambio actual ${{
              formatMoney(actualCurrency)
            }}
          </h4>
        </v-container>
        <v-stepper-header
          color="#F0BE43"
          class="mt-5 mr-15 ml-15"
          elevation="0"
          style="
            justify-content: center;
            align-items: center;
            justify-items: center;
            align-content: center;
            box-shadow: none;
          "
        >
          <v-stepper-step :complete="e1 > 1" step="1" color="#f0be43">
            Detalle
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2" color="#f0be43">
            Tipo de pago
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 3" step="3" color="#f0be43">
            Resumen
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1" elevation="0">
            <div>
              <div
                class="display-flex align-items-center justify-center flex-column"
              >
                <!-- Tabla con los invoices seleccionados -->
                <v-simple-table
                  style="width: 100%; border: 1px solid #e8e8e8"
                  elevation="0"
                >
                  <template class="full-width">
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ texts.dataTable.provider }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.invoice }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalAmount }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.currencyProfit }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalProtectedUsd }}
                        </th>
                        <th class="text-center">
                          {{ texts.dataTable.totalUsdToday }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="exchangeRateData in itemsSelected"
                        :key="exchangeRateData.id"
                      >
                        <td class="text-center">
                          {{ exchangeRateData.sProviderName }}
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.sInvoiceNumber }}
                        </td>
                        <td class="text-center">
                          ${{ formatMoney(exchangeRateData.dTotalAmount) }}
                          MXN
                        </td>
                        <td class="text-center">
                          {{ exchangeRateData.dCurrencyProfitPercentage }}
                          %
                        </td>
                        <td class="text-center">
                          {{ formatMoney(exchangeRateData.dTotalProtectedUsd) }}
                          USD
                        </td>
                        <td class="text-center">
                          {{ formatMoney(exchangeRateData.dTotalUsdToday) }}
                          USD
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
            <div style="display: flex; justify-content: end; margin-top: 20px">
              <v-btn
                class="button-save"
                style="max-width: 200px"
                elevation="0"
                @click="$emit('update-e1', 2)"
                >Continue
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <!-- Cards -->
            <v-container class="mt-1">
              <v-container class="d-flex flex-column align-center">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-card
                      class="d-flex pa-6"
                      outlined
                      :class="{ 'selected-card': selectedCard === 1 }"
                      @click="selectCard(1)"
                      elevation="0"
                      style="
                        align-self: stretch;
                        background: white;
                        border-radius: 10px;
                        border: 1px solid #dddddd;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: 16px;
                      "
                    >
                      <div
                        class="d-flex flex-column"
                        style="flex: 1 1 0; gap: 8px; align-items: flex-start"
                      >
                        <div
                          class="subtitle-1 font-weight-bold"
                          style="color: #302e2e; font-size: 24px"
                        >
                          {{ texts.payments.payNow }}
                        </div>
                        <div
                          class="body-1"
                          style="
                            color: #666565;
                            width: 762px;
                            text-align: justify;
                          "
                        >
                          $ {{ formatMoney(totalUsd) }} USD
                        </div>
                        <div class="body-1" style="color: #666565">
                          {{ texts.payments.payNowDescription }}
                        </div>
                      </div>
                      <v-icon v-if="selectedCard === 1" color="#f0be43"
                        >mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="grey lighten-1"
                        >mdi-checkbox-blank-circle-outline
                      </v-icon>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-card
                      class="d-flex pa-6"
                      outlined
                      :class="{ 'selected-card': selectedCard === 2 }"
                      @click="selectCard(2)"
                      elevation="0"
                      style="
                        align-self: stretch;
                        background: white;
                        border-radius: 10px;
                        border: 1px solid #dddddd;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: 16px;
                      "
                    >
                      <div
                        class="d-flex flex-column"
                        style="flex: 1 1 0; gap: 8px; align-items: flex-start"
                      >
                        <div
                          class="subtitle-1 font-weight-bold"
                          style="color: #302e2e; font-size: 24px"
                        >
                          {{ texts.payments.paySeparate }}
                        </div>
                        <div
                          class="body-1"
                          style="
                            color: #666565;
                            width: 762px;
                            text-align: justify;
                          "
                        >
                          {{ texts.payments.paySeparateDescription }}
                        </div>
                      </div>
                      <v-icon v-if="selectedCard === 2" color="#f0be43"
                        >mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="grey lighten-1"
                        >mdi-checkbox-blank-circle-outline
                      </v-icon>
                    </v-card>
                  </v-col>

                  <!-- <v-col cols="12">
                    <v-card
                      class="d-flex pa-6"
                      outlined
                      :class="{ 'selected-card': selectedCard === 3 }"
                      @click="selectCard(3)"
                      elevation="0"
                      style="
                        align-self: stretch;
                        background: white;
                        border-radius: 10px;
                        border: 1px solid #dddddd;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: 16px;
                      "
                    >
                      <div
                        class="d-flex flex-column"
                        style="flex: 1 1 0; gap: 8px; align-items: flex-start"
                      >
                        <div
                          class="subtitle-1 font-weight-bold"
                          style="color: #302e2e; font-size: 24px"
                        >
                          {{ texts.payments.payDeferred }}
                        </div>
                        <div
                          class="body-1"
                          style="
                            color: #666565;
                            width: 762px;
                            text-align: justify;
                          "
                        >
                          {{ texts.payments.payDeferredDescription }}
                        </div>
                      </div>
                      <v-icon v-if="selectedCard === 3" color="#f0be43"
                        >mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="grey lighten-1"
                        >mdi-checkbox-blank-circle-outline
                      </v-icon>
                    </v-card>
                  </v-col> -->

                  <v-col cols="12" v-if="isForwardsActive">
                    <v-card
                      class="d-flex pa-6"
                      outlined
                      :class="{ 'selected-card': selectedCard === 4 }"
                      @click="selectCard(4)"
                      elevation="0"
                      style="
                        align-self: stretch;
                        background: white;
                        border-radius: 10px;
                        border: 1px solid #dddddd;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: 16px;
                      "
                    >
                      <div
                        class="d-flex flex-column"
                        style="flex: 1 1 0; gap: 8px; align-items: flex-start"
                      >
                        <div
                          class="subtitle-1 font-weight-bold"
                          style="color: #302e2e; font-size: 24px"
                        >
                          {{ texts.payments.payForward }}
                        </div>
                        <div
                          class="body-1"
                          style="
                            color: #666565;
                            width: 762px;
                            text-align: justify;
                          "
                        >
                          {{ texts.payments.payForwardDescription }}
                        </div>
                      </div>
                      <v-icon v-if="selectedCard === 4" color="#f0be43"
                        >mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="grey lighten-1"
                        >mdi-checkbox-blank-circle-outline
                      </v-icon>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <div class="display-flex align-flex-end mt-2">
                <v-btn
                  elevation="0"
                  class="button-cancel mon-regular"
                  @click="$emit('update-e1', 1)"
                >
                  {{ texts.modalForm.cancel }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  elevation="0"
                  class="button-save mon-bold"
                  :disabled="selectedCard === 0"
                  @click="changeToResume()"
                >
                  Continue
                </v-btn>
              </div>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="3">
            <!-- Forwards -->
            <div
              class="mt-1"
              style="display: flex; width: 100%"
              v-if="isForwards"
            >
              <div class="container-dialog">
                <!-- <close-dialog-component @closeDialog="closeModalStepper()"/> -->

                <!-- <div class="py-4">
                  <h2>
                    When executing this forward you agree on two conditions
                  </h2>
                  <h4>
                    1. The chosen date is the day on which the agreed payment
                    will be executed. Please load the corresponding funds before
                    the deadline.
                  </h4>
                  <h4>
                    2. You will have to deposit 5% of the funds for the forward
                    in the next 24 hours for this operation to remain valid.
                  </h4>
                </div> -->

                <div
                  class="display-flex align-items-center justify-center flex-column"
                >
                  <!-- Tabla con los invoices seleccionados -->
                  <v-simple-table style="width: 100%">
                    <div class="mt-1">
                      <h4 style="align-items: start; justify-content: start">
                        Elige la fecha de pago para cada factura
                      </h4>
                    </div>
                    <template class="full-width">
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ texts.dataTable.provider }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.invoice }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalAmount }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalUsdToday }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.currencyProfit }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.paymentDate }}
                          </th>
                          <th class="text-center">Forward rate calculated</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="exchangeRateData in itemsSelected"
                          :key="exchangeRateData.id"
                        >
                          <td class="text-center">
                            {{ exchangeRateData.sProviderName }}
                          </td>
                          <td class="text-center">
                            {{ exchangeRateData.sInvoiceNumber }}
                          </td>
                          <td class="text-center">
                            ${{ formatMoney(exchangeRateData.dTotalAmount) }}
                            MXN
                          </td>
                          <td class="text-center">
                            {{ formatMoney(exchangeRateData.dTotalUsdToday) }}
                            USD
                          </td>
                          <td class="text-center">
                            {{ exchangeRateData.dCurrencyProfitPercentage }}
                            %
                          </td>
                          <td class="text-center" style="max-width: 120px">
                            <v-text-field
                              v-model="exchangeRateData.dForwardDate"
                              type="date"
                              outlined
                              dense
                              required
                              :placeholder="texts.modalForm.paymentDate"
                              persistent-placeholder
                            ></v-text-field>
                          </td>
                          <td class="text-center rate-highlight">
                            {{
                              formatMoney(
                                exchangeRateData.sExchangeRateForward
                                  ? exchangeRateData.sExchangeRateForward
                                  : "0.00"
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>

                <div v-if="isResponsive" class="mt-2">
                  <v-btn
                    elevation="0"
                    @click="getForwards()"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-primary mon-bold"
                    style="width: 100%"
                  >
                    Pay
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="$emit('update-e1', 2)"
                    class="button-secondary mon-regular mt-5 mb-2"
                    style="width: 100%"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                </div>
                <div v-else class="display-flex align-items-center mt-2">
                  <v-btn
                    elevation="0"
                    @click="$emit('update-e1', 2)"
                    class="button-cancel mon-regular"
                    style="max-width: 200px"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    @click="sendForwardPayment()"
                    :disabled="!isForwardsPayment"
                    :loading="bLoading"
                    class="button-save mon-bold mx-8"
                    style="max-width: 200px"
                  >
                    Pay
                  </v-btn>
                  <v-btn
                    elevation="0"
                    @click="getForwards()"
                    :loading="bLoading"
                    :disabled="!validateForm"
                    class="button-save mon-bold"
                    style="max-width: 200px"
                  >
                    Calculate Rate
                  </v-btn>
                </div>
              </div>
            </div>

            <!-- Adjusts -->
            <div
              class="display-flex align-items-center justify-center flex-column"
              v-else-if="showAdjusts"
            >
              <v-data-table
                :headers="invoiceHeaders"
                :items="itemsSelected"
                :single-expand="true"
                :expanded.sync="expanded"
                item-key="sExchangeRateId"
                show-expand
                style="width: 100%"
                :items-per-page="-1"
                hide-default-footer
                class="justify-center adjust-table"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Ajustes disponibles</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template
                  v-slot:expanded-item="{ headers, item }"
                  class="full-width justify-center"
                >
                  <td :colspan="headers.length" style="margin: 100px">
                    <v-simple-table style="width: 100%">
                      <template>
                        <thead>
                          <tr>
                            <th class="text-center">Supplier</th>
                            <th class="text-center">Inovice</th>
                            <th class="text-center">Monto</th>
                            <th class="text-center">Tipo de cambio</th>
                            <th class="text-center">Seleccionar</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="adjustment in getAvailableAdjustments(item)"
                            :key="adjustment.sAdjustId"
                          >
                            <td class="text-center">
                              {{ adjustment.exchangeRate.contactWallet.sName }}
                            </td>
                            <td class="text-center">
                              {{ adjustment.exchangeRate.sInvoiceNumber }}
                            </td>
                            <td class="text-center">
                              ${{ formatMoney(adjustment.dAmount) }} MXN
                            </td>
                            <td class="text-center">
                              {{ adjustment.sCurrency }}
                            </td>
                            <td class="text-header text-center">
                              <v-checkbox
                                v-model="adjustment.selected"
                                @change="
                                  updateSelectedAdjustments(item, adjustment)
                                "
                                color="#000000"
                              ></v-checkbox>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
              <div class="mt-4">
                Monto total: ${{ formatMoney(totalUsd) }} USD
              </div>
              <div class="display-flex align-flex-end mt-2">
                <v-btn
                  class="button-cancel mon-regular"
                  @click="$emit('update-e1', 2)"
                  >{{ texts.modalForm.cancel }}</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  class="button-save mon-bold"
                  style="max-width: 200px"
                  :loading="bLoading"
                  @click="switchPay"
                  >Pay</v-btn
                >
              </div>
            </div>

            <!-- Pay defferred-->
            <div v-else>
              <div>
                <!-- <div class="py-4">
                  <h2>
                    When executing this deferred payment you agree on two
                    conditions
                  </h2>
                  <h4>
                    1. You have to pay the total amount of the payment in the
                    next 2 hours for this operation to remain valid.
                  </h4>
                  <h4>
                    2. If you do not pay the total amount in the next 2 hours,
                    the operation will be canceled and you will have to pay a
                    penalty.
                  </h4>
                </div> -->

                <div
                  class="display-flex align-items-center justify-center flex-column"
                >
                  <!-- Tabla con los invoices seleccionados -->
                  <v-simple-table style="width: 100%" elevation="0">
                    <div>Resumen</div>
                    <template class="full-width">
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ texts.dataTable.provider }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.invoice }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalAmount }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.currencyProfit }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalProtectedUsd }}
                          </th>
                          <th class="text-center">
                            {{ texts.dataTable.totalUsdToday }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="exchangeRateData in itemsSelected"
                          :key="exchangeRateData.id"
                        >
                          <td class="text-center">
                            {{ exchangeRateData.sProviderName }}
                          </td>
                          <td class="text-center">
                            {{ exchangeRateData.sInvoiceNumber }}
                          </td>
                          <td class="text-center">
                            ${{ formatMoney(exchangeRateData.dTotalAmount) }}
                            MXN
                          </td>
                          <td class="text-center">
                            {{ exchangeRateData.dCurrencyProfitPercentage }}
                            %
                          </td>
                          <td class="text-center">
                            {{
                              formatMoney(exchangeRateData.dTotalProtectedUsd)
                            }}
                            USD
                          </td>
                          <td class="text-center">
                            {{ formatMoney(exchangeRateData.dTotalUsdToday) }}
                            USD
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: end;
                    margin-top: 20px;
                    margin-right: 15px;
                  "
                >
                  <strong
                    >Total a pagar: ${{ formatMoney(totalUsd) }} USD</strong
                  >
                </div>
              </div>
              <div class="display-flex align-flex-end mt-2">
                <v-btn
                  elevation="0"
                  class="button-cancel mon-regular"
                  @click="$emit('update-e1', 2)"
                >
                  {{ texts.modalForm.cancel }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  elevation="0"
                  class="button-save mon-bold"
                  style="max-width: 200px"
                  :loading="bLoading"
                  @click="switchPay"
                >
                  Pay
                </v-btn>
              </div>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: () => ""
    },
    highlightRate: false,
    dialogCreate: {
      type: Boolean,
      required: true
    },
    editableData: {
      type: Object,
      required: true
    },
    contacts: {
      type: Array,
      required: true
    },
    contactData: {
      type: Object,
      required: true
    },
    showCreateProvider: {
      type: Boolean,
      required: true
    },
    screenWidth: {
      type: Number,
      required: true
    },
    isResponsive: {
      type: Boolean,
      required: true
    },
    bLoading: {
      type: Boolean,
      required: true
    },
    validateForm: {
      type: Function,
      required: true
    },
    saveChanges: {
      type: Function,
      required: true
    },
    closeModalCreate: {
      type: Function,
      required: true
    },
    dialogPay: {
      type: Boolean,
      required: true
    },
    actualCurrency: {
      type: Number | String,
      default: "0"
    },
    itemsSelected: {
      type: Array,
      required: true
    },
    formatMoney: {
      type: Function,
      required: true
    },
    totalUsd: {
      type: Number | String,
      required: true
    },
    stepper: {
      type: Boolean,
      required: true
    },
    closeModalStepper: {
      type: Function,
      required: true
    },
    sendToPay: {
      type: Function,
      required: true
    },
    sendPayEfex: {
      type: Function,
      required: true
    },
    e1: {
      type: Number,
      required: true
    },
    isDeferredPayment: {
      type: Boolean,
      required: true
    },
    changeDeferredPayment: {
      type: Function,
      required: true
    },
    isPayMxn: {
      type: Boolean,
      required: true
    },
    changePayMxn: {
      type: Function,
      required: true
    },
    sendSeparateEfex: {
      type: Function,
      required: true
    },
    closeModalPay: {
      type: Function,
      required: true
    },
    closeDialog: {
      type: Function,
      required: true
    },
    dialogEditProfileActive: {
      type: Boolean,
      required: true
    },
    updateExchangeRate: {
      type: Function,
      required: true
    },
    backStep: {
      type: Function,
      required: true
    },
    getForwards: {
      type: Function,
      required: true
    },
    itemsSelectedForwards: {
      type: Array,
      required: true
    },
    isForwardRateCalculated: {
      type: Boolean,
      required: true
    },
    isForwardsPayment: {
      type: Boolean,
      required: true
    },
    sendForwardPayment: {
      type: Function,
      required: true
    },
    modalCurrency: {
      type: Number,
      required: true
    },
    exchangeRateAdjustments: {
      type: Array,
      required: true
    },
    formatDate: {
      type: Function,
      required: true
    },
    updateTotalUsd: {
      type: Function,
      required: true
    },
    isForwardsActive: {
      type: Boolean,
      required: true
    }
  },
  name: "ModalDashboard",

  data() {
    return {
      selectedCard: 0,
      showAdjusts: false,
      isForwards: false,
      adjustSelected: [],
      allSelected: false,
      totalProfitInvoice: 0,
      totalProfit: 0,
      totalMxn: 0,
      singleExpand: false,
      expanded: [],
      invoiceHeaders: [
        { text: this.texts.dataTable.provider, value: "sProviderName" },
        { text: this.texts.dataTable.invoice, value: "sInvoiceNumber" },
        {
          text: this.texts.dataTable.totalAmount,
          value: "dTotalAmountTemporal"
        },
        {
          text: this.texts.dataTable.currencyProfit,
          value: "dCurrencyProfitPercentage"
        },
        {
          text: this.texts.dataTable.totalProtectedUsd,
          value: "dTotalProtectedUsd"
        },
        { text: this.texts.dataTable.totalUsdToday, value: "dTotalUsdToday" },
        { text: "", value: "data-table-expand" }
      ]
    };
  },
  //methods
  methods: {
    selectCard(cardNumber) {
      this.selectedCard = cardNumber;
    },
    selectAllRows() {
      this.exchangeRateAdjustments.forEach((element) => {
        element.selected = this.allSelected;
      });
      //IF one of the rows is not selected, the button will be disabled
      this.allSelected = !this.exchangeRateAdjustments.some(
        (element) => element.selected === false
      );
    },
    changeToResume: function () {
      this.$emit("update-e1", 3);
      this.isForwards = false;
      this.showAdjusts = false;
      switch (this.selectedCard) {
        case 1:
          //console.log("Pagar ahora");
          // this.isForwards = false;
          // this.showAdjusts = true;
          break;
        case 2:
          //console.log("Separar pago");
          this.isForwards = false;
          this.showAdjusts = true;
          break;
        case 3:
          //console.log("Pago diferido");
          break;
        case 4:
          //console.log("Forwards");
          this.showAdjusts = false;
          this.isForwards = true;
          // this.changeForwards(true);
          // this.$emit("changeForwards", true);
          break;
      }
    },
    switchPay() {
      if (this.selectedCard === 1) {
        //this.stepper = false;
        //console.log("Pagar ahora");
        this.sendPayEfex();
        this.closeModalStepper();
        this.selectedCard = 0;
      } else if (this.selectedCard === 2) {
        //this.stepper = false;
        //console.log("Separar pago");
        //this.changeShowAdjusts(true);
        //this.e1 = 2;
        // this.isPayMxn = true;
        //this.changePayMxn();
        this.sendSeparateEfex();
        this.closeModalStepper();
        this.selectedCard = 0;
      } else if (this.selectedCard === 3) {
        //this.stepper = false;
        //console.log("Pago diferido");
        this.changeDeferredPayment();
        this.sendPayEfex();
        this.closeModalStepper();
        this.selectedCard = 0;
      } else if (this.selectedCard === 4) {
        //this.stepper = false;
        //this.changeForwards(true);
        //this.e1 = 2;
        //console.log("Forwards");
        this.selectedCard = 0;
      }
    },
    numberUnformatter() {
      return this.editableData.dTotalAmount.replace(/[^0-9.]/g, "");
    },

    getAvailableAdjustments(invoice) {
      return this.exchangeRateAdjustments.filter(
        (adjustment) =>
          !adjustment.selected ||
          adjustment.invoiceId === invoice.sExchangeRateId
      );
    },

    canSelectAdjustment(invoice, adjustment) {
      const selectedAdjustments = this.exchangeRateAdjustments.filter(
        (adj) => adj.selected && adj.invoiceId === invoice.sExchangeRateId
      );
      const totalSelectedAmount = selectedAdjustments.reduce(
        (total, adj) => total + adj.dAmount,
        0
      );
      return totalSelectedAmount + adjustment.dAmount <= invoice.amountOriginal;
    },

    updateSelectedAdjustments(invoice, adjustment) {
      // Actualizar el estado de selección del ajuste.
      if (adjustment.selected) {
        adjustment.invoiceId = invoice.sExchangeRateId;
      } else {
        adjustment.invoiceId = null;
      }
      // Llamar a calculateTotals para recalcular basado en el estado actualizado.
      this.calculateTotals(invoice);
    },

    calculateTotals(invoice) {
      if (invoice) {
        if (!invoice.amountOriginal) {
          invoice.amountOriginal = invoice.dTotalAmount;
        }

        const selectedAdjustments = this.exchangeRateAdjustments.filter(
          (adj) => adj.selected && adj.invoiceId === invoice.sExchangeRateId
        );

        invoice.Adjusts = [];

        selectedAdjustments.forEach((adj) => {
          invoice.Adjusts.push(adj.sAdjustId);
        });

        let totalSelectedAmount = selectedAdjustments.reduce((total, adj) => {
          return total + parseFloat(adj.dAmount);
        }, 0);

        invoice.dTotalAmountTemporal =
          parseFloat(invoice.amountOriginal) - totalSelectedAmount;

        let totalAdjustmentsUSD = selectedAdjustments.reduce((total, adj) => {
          return total + parseFloat(adj.dUsdAmount);
        }, 0);

        let remainingAmountInUSD = 0;
        if (invoice.dTotalAmountTemporal > 0) {
          remainingAmountInUSD =
            invoice.dTotalAmountTemporal / this.actualCurrency;
        }

        invoice.dCurrencyProfitPercentage = (
          ((invoice.dTotalProtectedUsd -
            totalAdjustmentsUSD -
            remainingAmountInUSD) /
            (totalAdjustmentsUSD + remainingAmountInUSD)) *
          100
        ).toFixed(2);
        invoice.remainingAmountInUSD = remainingAmountInUSD.toFixed(2);

        this.$emit("updateTotalUsd", invoice.remainingAmountInUSD);
      }
    },
    applyHighlightEffect() {
      // Encuentra todos los elementos que necesitas resaltar
      const elements = this.$el.querySelectorAll(".rate-highlight");
      elements.forEach((element) => {
        // Añade la clase 'highlight' para aplicar el efecto
        element.classList.add("highlight");

        // Opcionalmente, remueve la clase después de que el efecto haya completado
        // Ten en cuenta que este paso puede no ser necesario debido a la animación CSS
        setTimeout(() => {
          element.classList.remove("highlight");
        }, 1000); // Ajusta este tiempo según la duración de tu animación
      });
    }
  },
  mounted() {
    this.calculateTotals();
  },
  watch: {
    exchangeRateAdjustments: {
      handler: function (newValue, oldValue) {
        //this.calculateProfitAllInvoices();
      },
      deep: true
    },
    "exchangeRateData.sExchangeRateForward": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // Lógica para aplicar el efecto de resaltado
          this.applyHighlightEffect();
        }
      },
      deep: true // Utiliza `deep` si `exchangeRateData` es un objeto y quieres detectar cambios en sus propiedades
    }
  },
  computed: {
    formattedItems() {
      return this.itemsSelected.map((item) => ({
        ...item,
        dTotalAmount: this.formatMoney(item.dTotalAmount) + " MXN",
        dTotalProtectedUsd: this.formatMoney(item.dTotalProtectedUsd) + " USD",
        dTotalUsdToday: this.formatMoney(item.dTotalUsdToday) + " USD"
      }));
    },
    formattedTotalAmount: {
      get() {
        // Formatear el número para mostrarlo. Ajusta esta lógica según necesites.
        return this.formatMoney(this.editableData.dTotalAmount);
      },
      set(newValue) {
        // Eliminar formateo y actualizar el modelo de datos
        this.editableData.dTotalAmount = this.numberUnformatter(newValue);
      }
    }
  },
  updated() {
    this.applyHighlightEffect();
  }
};
</script>

<style scoped>
.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}

.button-save:disabled {
  background: gray !important;
  cursor: not-allowed;
  pointer-events: visible;
}

.button-cancel {
  width: 151px;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  word-wrap: break-word;
}

.button-save {
  height: 40px !important;
  width: 45% !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.textButton {
  text-align: center;
  color: #302e2e;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.cancel-text {
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  color: #f0be43;
  line-height: 24px;
  word-wrap: break-word;
}

.selected-card {
  border: 2px solid #f0be43 !important;
}

.card {
  align-self: stretch;
  background: white;
  border-radius: 10px;
  border: 1px solid #dddddd;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
}

.card-content {
  flex: 1 1 0;
  gap: 8px;
  align-items: flex-start;
}

.title {
  color: #302e2e;
  font-size: 24px;
  font-weight: bold;
}

.description {
  color: #666565;
  align-self: stretch;
  text-align: justify;
}

.v-divider {
  border: none;
  border-top: 7px #f0be44 solid !important;
  border-radius: 50px;
  margin-left: -120px !important;
  margin-right: -120px !important;
}

.no-elevation {
  box-shadow: none !important;
}

.text-center {
  text-align: center;
  font-family: montserrat-regular;
}

.yellow-icon {
  color: #f0be43 !important;
}

.v-stepper__step__step.primary {
  color: #f0be43 !important;
}

.highlight {
  animation: highlight-animation 1s ease;
}

@keyframes highlight-animation {
  from {
    background-color: yellow;
  }
  to {
    background-color: transparent;
  }
}

.adjust-table .v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
